import React from "react"

import Image from "../image"
import VideoInline from "../videoInline"
import styles from "./mediaMedia.module.scss"

const mediaMedia = props => {
  const smallMediaKind = props.data.smallMedia[0].kind
  const smallMedia = props.data.smallMedia[0]
  const largeMediaKind = props.data.largeMedia[0].kind
  const largeMedia = props.data.largeMedia[0]
  const verticalAlignment = props.data.verticalAlignment
  const reverseOrder = props.data.reverseOrder
  const isContained = props.data.contained
  const wrapperClass = isContained ? "wrapper" : "wrapper wrapper--full-bleed"

  let alignmentStyle

  if (verticalAlignment === "middle") {
    alignmentStyle = styles.isMiddle
  } else if (verticalAlignment === "bottom") {
    alignmentStyle = styles.isBottom
  } else {
    alignmentStyle = styles.isTop
  }

  return (
    <div className="module module--image">
      <div className={wrapperClass}>
        <div className={isContained ? "columns" : null}>
          <div
            className={
              isContained ? "column is-10-desktop is-offset-1-desktop" : null
            }
          >
            <div
              className={`${styles.moduleInner} ${alignmentStyle} ${
                reverseOrder ? styles.isReversed : ""
              }`}
            >
              <div className={styles.imageSmall}>
                <div className={`${isContained ? "no-padding-wrapper-mobile-only" : "wrapper"}`}>
                  {smallMediaKind === "video" ? (
                    <VideoInline
                      src={smallMedia.url}
                      type={smallMedia.mimeType}
                    />
                  ) : (
                    <Image
                       width={smallMedia.width}
                       height={smallMedia.height}
                      // Max-width is 1/3 of .wrapper (1344)
                      sizes="(min-width: 768px) 448px, calc(100vw - 2.5rem)"
                      url={smallMedia.url}
                      alt={smallMedia.altText}
                    />
                  )}
                </div>
              </div>
              <div className={styles.imageLarge}>
                {largeMediaKind === "video" ? (
                  <VideoInline
                    src={largeMedia.url}
                    type={largeMedia.mimeType}
                  />
                ) : (
                  <Image
                    width={largeMedia.width}
                    height={largeMedia.height}
                    sizes="(min-width: 1800px) 900px, (min-width: 768px) 50vw, 100vw"
                    url={largeMedia.url}
                    alt={largeMedia.altText}
                  />
                )}
              </div>
              {/* .moduleInner */}
            </div>
            {/* .column when isContainer */}
          </div>
          {/* .columns when isContainer */}
        </div>
        {/* .wrapper */}
      </div>
      {/* .module */}
    </div>
  )
}

export default mediaMedia
